import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// tools
import styled from 'styled-components';
import theme from 'theme';
import { getPdfSrc } from 'utils/pdf';
// actions
import { 
  selectMySessionPage as selectMySessionPageAction
} from "actions/mySession";
import { A2VOTE_API } from 'utils/config';
// utils
import Icon from "utils/icon";
//Il8n
import { FormattedMessage } from "react-intl";
import messages from "./messages";
import { Lazyload } from "../../../components/lazyload/lazyload";

const Return = styled.div`
  padding: 16px 32px;
  border-bottom: 1px solid ${theme.ui.grey};
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
  color: ${theme.darkgray};
`;

const DocCont = styled.div`
  width: 100%;
  height: calc(100vh - 130px);
`;

const MySessionHome = () => {
  //state
  const { selectedMySession } = useSelector((state) => state.mySession)
  const [numberPage, setNumberPage] = useState(0);

  const dispatch = useDispatch();
  const selectMySessionPage = () => dispatch(selectMySessionPageAction());

  useEffect(() => {
    const fetchData = async () => {
      const data = await fetch(`${A2VOTE_API}/file/m/${selectedMySession.filename}`);
      const json = await data.json();
      setNumberPage(json)
    }
    fetchData()
      .catch(console.error);
  }, [])

  const displayFile = () => {
    const filename = selectedMySession?.filename;
    if(filename) {
      if(filename.toLowerCase().includes('.pdf')) {
        let pdfSrc = getFileSource();
        pdfSrc = getPdfSrc(pdfSrc);
        return <iframe src={pdfSrc} width="100%" height="100%"  frameBorder="0"/>
      }
      else {
         if(numberPage == 1){
          return <Lazyload src={getFileSource()} />
        }
        else {
             const pages = [];
             for(let j=1;j<=numberPage;j++) {
               pages.push(<Lazyload key={j} src={getPagesFileSource(j)} />);
             }

           return (<div>{ pages }</div>);
        }
      }
    }
    return null;
  }
  const getPagesFileSource = (index) => {
    let filenameWithoutExtension = selectedMySession.filename?.replace('.jpg','');
    return `${A2VOTE_API}/file/a/${filenameWithoutExtension}/${index}`;
  };
  const getFileSource = () => {
    return `${A2VOTE_API}/file/o/${selectedMySession.filename}`;
  };
  return (
    <div>
      <Return onClick={selectMySessionPage}>
        <Icon icon="LeftArrow" size="sm" />
        <FormattedMessage {...messages.return} />
      </Return>
      <DocCont>
        {displayFile()}
      </DocCont>
    </div>
  )
}

export default MySessionHome;
// React
import React from "react";
// tools
import theme from 'theme';
import styled from "styled-components";
import { navigate } from "gatsby";
// components
import UserMenu from "./UserMenu";
import A2Logo from 'components/A2Logo';

const HVHContainer = styled.div`
  padding: 16px 32px;
  border-bottom: 1px solid ${theme.ui.grey};
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media(max-width: 768px) {
    padding: 16px 16px;
    gap: 8px;
  }
`;

const HVHMenuRef = styled.div`
  display: flex;
  flex-flow: row;
  align-items: center;
`;

const Welcome = styled.div`
  font-size: 22px;
  font-weight: bold;
  color: ${theme.darkgray};
  cursor: pointer;

  @media(max-width: 768px) {
    font-size: 18px;
  }
`;

const HomeVoteHeader = () => {
  return (
    <HVHContainer>
      <Welcome onClick={() => navigate("/")}>
        <A2Logo small/>
      </Welcome>
      <HVHMenuRef>
        <UserMenu />
      </HVHMenuRef>
    </HVHContainer>
  )
}

export default HomeVoteHeader;
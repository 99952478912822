import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// tools
import styled from 'styled-components';
import Icon from "utils/icon";
import theme from 'theme';
// i18n
import { FormattedMessage } from "react-intl";
import globalMessages from "containers/messages";
import messages from "./messages";
// actions
import {
  selectMySessionVote as selectMySessionVoteAction,
  sendMySessionVoteResult as sendMySessionVoteResultAction,
  selectMyProxyVote as selectMyProxyVoteAction
} from "actions/mySession";
// components
import ConfirmVote from './ConfirmVote';
import UserVotes from 'components/UserVotes';
// styled
import {
  Buttons,
  VoteButtonGreen,
  VoteButtonRed,
  VoteButtonBlue
} from "./styled";
import MyVoteDoc from './MyVoteDocument';

const MyVoteCont = styled.div`
  margin-bottom: 16px;
`;

const Return = styled.div`
  padding: 16px 32px;
  border-bottom: 1px solid ${theme.ui.grey};
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
  color: ${theme.darkgray};

  @media (max-width: 768px) {
    padding: 16px;
  }
`;


const Container = styled.div`
  padding: 16px 32px;

  @media (max-width: 768px) {
    padding: 16px;
  }
`;

const VoteHeader = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
`;

const Titles = styled.div`
  display: flex;
  gap: 8px;
`;

const TitleSéance = styled.div`
  font-size: 18px;
  font-weight: bold;
  color: ${theme.darkgray};
`;

const TitleVote = styled.div`
  font-size: 18px;
  font-weight: bold;
  color: ${theme.darkgray};
`;

const DescriptionVote = styled.div`
  color: ${theme.mediumgray};
`;

const Results = styled.div`
  margin-bottom: 16px;
`;

const Result = styled.div`
  height: 32px;
  margin-bottom: 8px;
  width: ${props => `${props.prct}%` || 0 };
  background: ${theme.ui.blue};
  color: ${theme.white};
  border-radius: 5px;
  white-space: nowrap;
  transition: all .4s;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ResultYes = styled(Result)`
  background: ${theme.lightgreen};
`;

const ResultNo = styled(Result)`
  background: ${theme.lightred};
`;

const MyVote = () => {
  const [openConfirm, setOpenConfirm] = useState(false);
  const [selectedResult, setSelectedResult] = useState(null);
  // global state
  const { selectedVote, selectedProxy, selectedMySession } = useSelector((state)=>state.mySession);
  // actions
  const dispatch = useDispatch();
  const selectMySessionVote = () => dispatch(selectMySessionVoteAction());
  const selectMyProxyVote = () => dispatch(selectMyProxyVoteAction());
  const sendMySessionVoteResult = (voteResult) => dispatch(sendMySessionVoteResultAction(selectedVote.id, voteResult, selectedProxy?.id));
  // compute data
  const resultsCount = selectedVote?.results?.length;
  const resultsYes = selectedVote?.results?.filter((result)=>result.simpleResult === 1);
  const resultsNo = selectedVote?.results?.filter((result)=>result.simpleResult === 0);
  const resultsAbstention = selectedVote?.results?.filter((result)=>result.simpleResult === 2);
  const pctgYes = Math.trunc(resultsYes.length/resultsCount * 100) || 0;
  const pctgNo = Math.trunc(resultsNo.length/resultsCount * 100) || 0;
  const pctgAbst = Math.trunc(resultsAbstention.length/resultsCount * 100) || 0;

  let voteResult = null;
  if(selectedProxy) {
    const mandatingId = selectedProxy?.mandating?.userId;
    const mandatingResult = selectedVote?.results?.find((result)=>result?.by?.userId === mandatingId || result?.by === mandatingId);
    voteResult = mandatingResult?.simpleResult;
  }
  else {
    voteResult = selectedVote?.userResult?.simpleResult
  }

  const selectResult = (result, voteResult) => {
    if(checkIsOpen() && !Number.isInteger(voteResult)) {
      setSelectedResult(result);
      setOpenConfirm(true);
    }
  }

  const resetResult = () => {
    setSelectedResult(null);
    setOpenConfirm(null);
  }

  const confirmResult = () => {
    sendMySessionVoteResult(selectedResult);
    setOpenConfirm(null);
  }

  const checkIsOpen = () => {
    return selectedMySession.status === 1 && selectedVote.status === 1;
  }

  const UserResults = styled.div`
    display: flex;
    flex-flow: wrap;
    justify-content: flex-start;
    gap: 8px;
    overflow-y: auto;
    margin-top: 16px;
    padding: 16px 0;
    border-top: 1px dashed #C4C4C4;
    border-bottom: 1px dashed #C4C4C4;

    @media(max-width: 900px) {
      overflow-y: auto;
    }
`;

  return (
    <MyVoteCont>
      <Return onClick={selectedProxy ? selectMyProxyVote : selectMySessionVote}>
          <Icon icon="LeftArrow" size="sm"/>
          <FormattedMessage {...globalMessages.return} />
      </Return>
      <Container>
        <VoteHeader>
          <Titles>
            <TitleSéance>{selectedMySession.title}</TitleSéance>
            -
            <TitleVote>{selectedVote.title}</TitleVote>
          </Titles>
          <DescriptionVote>{selectedVote.description}</DescriptionVote>
        </VoteHeader>
        {
          selectedMySession?.status > 0 &&
            <Results>
              <ResultYes prct={pctgYes}>
                {resultsYes.length} - {pctgYes}%
              </ResultYes>
              <ResultNo prct={pctgNo}>
                {resultsNo.length} - {pctgNo}%
              </ResultNo>
              <Result prct={pctgAbst}>
                {resultsAbstention.length} - {pctgAbst}%
              </Result>
            </Results>
        }
        {
          checkIsOpen() && openConfirm ?
            <ConfirmVote selectedResult={selectedResult} resetResult={resetResult} confirmResult={confirmResult}/>
          : (
            <Buttons>
              <VoteButtonGreen onClick={()=>selectResult(1, voteResult)} selected={voteResult === 1} disable={!checkIsOpen() || Number.isInteger(voteResult)}>
                <FormattedMessage {...messages.yes} />
              </VoteButtonGreen>
              <VoteButtonRed onClick={()=>selectResult(0, voteResult)} selected={voteResult === 0} disable={!checkIsOpen() || Number.isInteger(voteResult)}>
                <FormattedMessage {...messages.no} />
              </VoteButtonRed>
              <VoteButtonBlue onClick={()=>selectResult(2, voteResult)} selected={voteResult === 2} disable={!checkIsOpen() || Number.isInteger(voteResult)}>
                <FormattedMessage {...messages.abstain} />
              </VoteButtonBlue>
            </Buttons>
          )
        }
        <div>
          <UserResults>
            <UserVotes userLists={selectedMySession?.userLists} proxies={selectedMySession?.proxies} results={selectedVote?.results}/>
          </UserResults>
          <MyVoteDoc />
        </div>
      </Container>
    </MyVoteCont>
  )
}

export default MyVote;
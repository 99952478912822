import React, { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// actions
import { 
  getMySessionVote as getMySessionVoteAction,
  clearMySessionVotes as clearMySessionVotesAction
} from "actions/mySession";
// containers
import MySessionHome from './MySessionHome';
import MySessionDocument from './MySessionDocument';
import MySessionReport from './MySessionReport';
import MyVote from '../MyVote';

const MySession = () => {
  const { mySessionPage, selectedVote, selectedMySession } = useSelector((state) => state.mySession)
  
  const dispatch = useDispatch();
  const getMySessionVotes = useCallback((sessionId) => dispatch(getMySessionVoteAction(sessionId)), [dispatch]);
  const clearMySessionVotes = useCallback(() => dispatch(clearMySessionVotesAction()), [dispatch]);

  useEffect(()=>{
    if(selectedMySession) {
      getMySessionVotes(selectedMySession?.id);
    }
    const interval = setInterval(() => {
      if(selectedMySession) {
        getMySessionVotes(selectedMySession?.id);
      }
    }, 15000);
    return () => clearInterval(interval);
  }, [getMySessionVotes])

  // On unmount
  useEffect(()=>{
    return (()=>{
      clearMySessionVotes();
    })
  }, [clearMySessionVotes]);

  const selectDisplay = () => {
    switch(mySessionPage) {
      case "home":
        return <MySessionHome/>;
      case "document":
        return <MySessionDocument/>;
      case "report":
        return <MySessionReport/>;
      default:
        return <div>error</div>
    }
  }

  if(selectedVote) {
    return (
      <MyVote />
    )
  }

  return (
    <React.Fragment>
      {selectDisplay()}
    </React.Fragment>
  )
}

export default MySession;
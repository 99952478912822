import { defineMessages } from "react-intl";
export default defineMessages({
  yes: {
    id: "a2vote.myvote.yes",
    defaultMessage: "Yes",
  },
  no: {
    id: "a2vote.myvote.no",
    defaultMessage: "No",
  },
  abstain: {
    id: "a2vote.myvote.abstain",
    defaultMessage: "Abstain",
  },
  validate: {
    id: "a2vote.myvote.validate",
    defaultMessage: "Validate"
  },
  cancel: {
    id: "a2vote.cancel",
    defaultMessage: "Cancel"
  }
});
